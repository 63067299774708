import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from 'utils/localized-link';

import BoxIcon from 'images/icons/box.inline.svg';
import StarIcon from 'images/icons/star.inline.svg';
import TrophyIcon from 'images/icons/trophy.inline.svg';

import Button from '@youship/components/objects/button';
import Diagram from 'components/diagram';

import './styles.scss';

const BENEFITS = [
  {
    title: 'home.benefits.b1.title',
    description: 'home.benefits.b1.description',
    icon: TrophyIcon
  },
  {
    title: 'home.benefits.b2.title',
    description: 'home.benefits.b2.description',
    icon: StarIcon
  },
  {
    title: 'home.benefits.b3.title',
    description: 'home.benefits.b3.description',
    icon: BoxIcon
  }
];

const HomeBenefits = () => (
  <div className="home-benefits">
    <div className="container">
      <div className="row d-flex align-items-center">
        <div className="col col-12 col-md-6 offset-md-0 col-xl-5 offset-xl-1">
          <h6>
            <FormattedMessage id="home.benefits.title" />
          </h6>
          <h2 className="h2--display home-benefits__description">
            <FormattedMessage id="home.benefits.description" />
          </h2>
          <Button
            context="primary"
            linkComponent={Link}
            linkProps={{ to: '/how-it-works' }}
            noBackground
            noPadding
            noShadow
            small
          >
            <FormattedMessage id="home.benefits.link" />
          </Button>
        </div>
        <div className="col col-12 col-md-6 col-lg-5 offset-lg-1">
          <Diagram items={BENEFITS} />
        </div>
      </div>
    </div>
  </div>
);

export default HomeBenefits;
