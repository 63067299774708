import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import HomeApp from 'components/sections/home-app';
import HomeBenefits from 'components/sections/home-benefits';
import HomeGrow from 'components/sections/home-grow';
import HomeHeader from 'components/sections/home-header';
import HomeTendencies from 'components/sections/home-tendencies';
import Layout from 'components/layout';
import SEO from 'components/seo';

import bannerImage from 'images/create-account.png';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const IndexPage = ({ pageContext: { locale } }) => {
  const bannerProps = {
    buttons: [
      {
        external: true,
        linkProps: {
          to: `${WEB_APP_URL}/register`
        },
        light: true,
        text: 'home.banner.buttons.sign_up',
        noArrow: true,
        small: true
      },
      {
        linkComponent: Link,
        linkProps: {
          to: '/contacts'
        },
        light: true,
        noArrow: true,
        noBackground: true,
        small: true,
        text: 'home.banner.buttons.contacts'
      }
    ],
    image: bannerImage,
    textMessageId: 'home.banner.text',
    titleMessageId: 'home.banner.title'
  };

  return (
    <Layout locale={locale}>
      <SEO title="home.title" />
      <HomeHeader />
      <HomeBenefits />
      <HomeTendencies />
      <HomeApp />
      <HomeGrow />
      <Banner
        image="bannerImage"
        text="home.banner.text"
        title="home.banner.title"
        {...bannerProps}
      />
    </Layout>
  );
};

export default IndexPage;
