import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '@youship/components/objects/button';
import Img from 'components/image';

import './styles.scss';

const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const headerImage1 = 'home/header_image_1.jpg';
const headerImage2 = 'home/header_image_2.jpg';

const HomeHeader = ({ intl }) => (
  <div className="home-header">
    <div className="container">
      <div className="row home-header__row d-flex align-items-center">
        <div className="col col-12 col-md-6 offset-md-2 order-md-3">
          <div className="home-header__text">
            <h1 className="h1--display">
              <FormattedMessage id="home.header.title" />
            </h1>
            <div className="home-header__input-wrapper how-it-works-header__buttons">
              <Button
                classNames="how-it-works-header__button"
                context="primary"
                external
                linkProps={{ to: `${WEB_APP_URL}/new-order` }}
                noArrow
                noNewTab
                small
                text={intl.formatMessage({ id: 'works.header.send' })}
              />
              <Button
                classNames="how-it-works-header__button"
                context="primary"
                external
                linkProps={{ to: WEB_APP_URL }}
                noArrow
                noNewTab
                outline
                small
                text={intl.formatMessage({ id: 'works.header.receive' })}
              />
              {/* TODO: IMPLEMENT THE ACTUAL ADDRESS SEARCH */}
              {/* <InputAddressSearch
                placeholder={intl.formatMessage({ id: 'home.header.address_search_input.placeholder' })}
                submitButtonText={intl.formatMessage({ id: 'home.header.address_search_input.submit_button' })}
                value={address}
                onChange={event => setAddress(event?.target?.value ?? '')}
              /> */}
            </div>
          </div>
        </div>
        <div className="col col-10 col-sm-8 offset-sm-1 col-md-4 offset-md-0 col-xl-4 order-md-1">
          <div className="d-none d-md-block home-header__background-element" />
          <Img
            alt="Header 1"
            classNames="home-header__image"
            src={headerImage1}
          />
        </div>
        <div className="col col-10 offset-2 col-sm-7 offset-sm-4 col-md-3 offset-md-2 col-xl-3 offset-xl-2 order-md-2 home-header__image-column">
          <Img
            alt="Header 2"
            classNames="home-header__image home-header__image--small"
            src={headerImage2}
          />
        </div>
      </div>
    </div>
  </div>
);

export default injectIntl(HomeHeader);
