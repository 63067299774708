import React from 'react';
import { FormattedMessage } from 'react-intl';

import 'swiper/swiper.scss';

import iphoneImage from 'images/home/iPhone.png';
import iphoneMobileImage from 'images/home/iPhone--mobile.png';
import smartphoneImage from 'images/home/Smartphone.png';
import DownloadIosImage from 'images/home/download-ios.inline.svg';
import DownloadAndroidImage from 'images/home/download-android.inline.svg';

import './styles.scss';

const HomeApp = () => (
  <div className="home-app">
    <div className="container">
      <div className="home-app__separator" />
      <div className="row">
        <div className="col col-12 offset-0 col-md-6 offset-md-2 col-lg-5 order-md-2">
          <div className="home-app__text-container">
            <h3 className="h3--display">
              <FormattedMessage id="home.install_app.title" />
            </h3>
            <div className="home-app__highlight">
              <FormattedMessage id="home.install_app.app_name" />
            </div>
            <div className="home-app__text">
              <FormattedMessage id="home.install_app.text" />
            </div>
            <div className="home-app__download">
              <a
                className="home-app__download-link"
                href="https://itunes.apple.com/pt/app/youship/id1077762019?l=pt&ls=1&mt=8"
                rel="noopener noreferrer"
                target="_blank"
              >
                <DownloadIosImage className="home-app__download-button" />
              </a>
              <a
                className="home-app__download-link"
                href="https://play.google.com/store/apps/details?id=com.shipnow.youship"
                rel="noopener noreferrer"
                target="_blank"
              >
                <DownloadAndroidImage className="home-app__download-button" />
              </a>
            </div>
          </div>
        </div>
        <div className="col col-12 offset-0 col-md-4 offset-md-0 order-md-1 offset-lg-1">
          <div className="home-app__mockups-wrapper">
            <img
              alt="iPhone"
              className="d-none d-md-block home-app__image"
              src={iphoneImage}
            />
            <img
              alt="iPhone"
              className="d-md-none home-app__image"
              src={iphoneMobileImage}
            />
            <img
              alt="iPhone"
              className="home-app__image--smartphone"
              src={smartphoneImage}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomeApp;
