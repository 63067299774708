import React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import Button from '@youship/components/objects/button';
import OrderTypeCard from 'components/order-type-card';

import './styles.scss';

const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const restaurantsImage = 'categories/restaurants.jpg';
const bookstoresImage = 'categories/bookstores.jpg';
const photocopiesImage = 'categories/photocopies.jpg';
const dressmakersImage = 'categories/dressmakers.jpg';
const bakeriesImage = 'categories/bakeries.jpg';

const HomeTendencies = () => {
  const intl = useIntl();

  return (
    <div className="home-tendencies">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-md-6 col-xl-4">
            <h6>
              <FormattedMessage id="home.tendencies.title" />
            </h6>
            <h2 className="h2--display home-tendencies__description">
              <FormattedMessage id="home.tendencies.description" />
            </h2>
          </div>
          <div className="col col-12 col-md-6 col-xl-8 d-flex justify-content-md-end align-items-md-end">
            <Button
              context="primary"
              external
              linkProps={{ to: WEB_APP_URL }}
              noBackground
              noNewTab
              noPadding
              noShadow
              small
            >
              <FormattedMessage id="home.tendencies.link" />
            </Button>
          </div>
        </div>
        <Swiper
          breakpoints={{
            992: {
              spaceBetween: 32
            }
          }}
          className="home-tendencies__slider"
          slidesPerView="auto"
          spaceBetween={16}
        >
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.restaurants.description' })}
              id="food"
              imageUrl={restaurantsImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.restaurants.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.bookstores.description' })}
              id="bookstores"
              imageUrl={bookstoresImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.bookstores.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.photocopies.description' })}
              id="photocopies"
              imageUrl={photocopiesImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.photocopies.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.dressmakers.description' })}
              id="dressmakers"
              imageUrl={dressmakersImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.dressmakers.title' })}
              noMargin
            />
          </SwiperSlide>
          <SwiperSlide className="home-tendencies__slider-slide">
            <OrderTypeCard
              classNames="home-tendencies__slider-card"
              description={intl.formatMessage({ id: 'home.tendencies.swiper.bakeries.description' })}
              id="bakeries"
              imageUrl={bakeriesImage}
              name={intl.formatMessage({ id: 'home.tendencies.swiper.bakeries.title' })}
              noMargin
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default injectIntl(HomeTendencies);
